select {
    background-color: transparent;
    border: 0px;
    font-size: 18px;
    padding: 6px;
}

select:hover {
    background-color: rgba(200,200, 200, 0.3);
}

select:focus {
    border: 0px;
    border-color: transparent;
    outline: none;
}

option {
    background-color: white;
}