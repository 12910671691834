

.list {
    
display:flex;
justify-content: center;

background-color: white;

  }

.search {
  display:flex;
  justify-content: center;
  margin-top:10px;
}


.dotGreen{
  height: 10px;
  width: 10px;
  background-color: rgb(13, 148, 13);
  border-radius: 50%;
  display: inline-block;

}

.dotOrange{
  height: 10px;
  width: 10px;
  background-color: rgb(160, 124, 61);
  border-radius: 50%;
  display: inline-block;
}

.dotGray{
  height: 10px;
  width: 10px;
  background-color: rgb(175, 174, 171);
  border-radius: 50%;
  display: inline-block;
}
.dotRed{
  height: 10px;
  width: 10px;
  background-color: rgb(194, 13, 52);
  border-radius: 50%;
  display: inline-block;
}

.dotBlue{
  height: 10px;
  width: 10px;
  background-color: rgb(56, 105, 196);
  border-radius: 50%;
  display: inline-block;
}

.dotYellow{
  height: 10px;
  width: 10px;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  display: inline-block;
}


.fieldset {
 //width:min-content;
flex-wrap : wrap;
height :max-content;
padding:25px;
border-radius: 8px;

}

