@import '../../assests/styles/base.scss';

.el-step__head {
    width: 52px;
    height: 52px;
    font-size: 52px;
}

.el-step__line.is-vertical {
    left: 24px;
    top: 44px;
}

.el-step__icon {
    line-height: 52px;
}

.el-tabs__header {
    text-align: center;
    display: flex;
    justify-content: center;
    position: sticky;
    background-color: white;
    z-index: 999;
    top: 0;
}


.elevate {
    z-index: 2 !important;
    position: absolute;
    height:2px !important;
}

.disabled :active {
    pointer-events:none;
}

.disabledshift  {
    pointer-events:none;
}
