$color-primary: #005bab;

// @import "~element-theme-default";
// @import "~theme-default-scss";

html {
  height: 1px;
  min-height: 100%;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
}

body {
  height: 100%;
  width: 100%;
  margin: 0px;
}

#root {
  width: 100%;
}

a {
  text-decoration: none;
  display: block;
}

.el-menu {
  border-radius: 0px;
}

.el-menu--horizontal > .el-menu-item.dock-right {
  float: right;
}

.circle {
  display: inline-block;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  padding: 1rem;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
}

.circle:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background-color: red;
  padding-top: 100%;
  height: 0;
}

.circle > * {
  display: inline-block;
  vertical-align: middle;
}

.middle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@for $i from 0 through 24 {
  .col-order-#{$i} {
    order: $i;
  }
}

@media only screen and (min-width: 0px) {
  @for $i from 0 through 24 {
    .col-xs-order-#{$i} {
      order: $i;
    }
  }
}

@media only screen and (min-width: 768px) {
  @for $i from 0 through 24 {
    .col-sm-order-#{$i} {
      order: $i;
    }
  }
}

@media only screen and (min-width: 992px) {
  @for $i from 0 through 24 {
    .col-md-order-#{$i} {
      order: $i;
    }
  }
}

@media only screen and (min-width: 1200px) {
  @for $i from 0 through 24 {
    .col-lg-order-#{$i} {
      order: $i;
    }
  }
}

@media only screen and (max-width: 600px) {
  .el-dialog {
    width: 80%;
  }
}

.ui-tooltip__content {
  position: absolute;
  left: -100%;
  top: 0;
}

.ui-dialog {
  width: 500px !important;
  max-width: 80%;
}
