$color-primary: #005bab;

.mainHeader {
    color: $color-primary;
    justify-content: center;
    font-weight: bolder;
    font-size: medium;
    text-align: center;
}

.subHeader {
    color: $color-primary;
    justify-content: center;
    font-weight: bolder;
    font-size: 17px;
}