.main-row {
    height: 100%;
    align-items: stretch;
    justify-content: center;
    min-height: 100%;
    flex-wrap: wrap;
    align-content: stretch;
}

.timer-row {
    text-align: center;
    min-height: 100%;
    flex-wrap: wrap;
    align-content: space-around;
}

.side-panel {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
    direction: rtl;
    padding: 0;
}

.side-panel > * {
    direction: ltr;
}

.ms-FocusZone {
    overflow-x: auto;
}
