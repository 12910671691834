.PhoneInput {
    height : 100%
}

.PhoneInput .PhoneInputCountry {
    height : 100%
}

.PhoneInput .PhoneInputInput {
    height : 100%
}